import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray} from '@angular/forms';
import { Router } from '@angular/router';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import { ToastrService } from 'ngx-toastr';
import { ValidadoresService } from 'src/app/services/shared/validators';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-controlusers',
  templateUrl: './controlusers.component.html',
  styleUrls: ['./controlusers.component.css']
})
export class ControlUsersComponent implements OnInit {
  page = 0;
  pageR = 0;
  total = 0;
  totalR = 0;
  form: FormGroup;
  form2: FormGroup;
  form3: FormGroup;//Edicion por recibo
  organizaciones: any = [];
  beAbleLimitUsers = true;
  obteniendoInformacion=false;
  verOrgsSinRecibos=false;
  verOrgsSinRecibosOrg=false;
  myControl = new FormControl();
  previous = null;
  turnOffPagination = false;
  turnOffPaginationR = false;
  next = null;
  orgSelected = '';
  organizacionF = '';
  usuarios_del_plan = 0;
  button_active = 1;  
  orgs: any = [];
  styleBtnActive;
  styleBtnInactive;
  styleBtnAll;
  showUserActive;
  showUserInactive;
  showUserAll;
  showReceiptEdit=false;
  verDashboardRecs=true;
  showInfo = false;
  logo:string;
  showReceipts:false;
  showReceiptsRen:false;
  formsPay;
  statusRecibo;
  statusReciboFiltro;
  receipts_org: any =[];
  recibos_creados: any =[];
  rec_edit;
  index_edit;
  recibosOrg: any;
  estadisticaRecibos: any;  
  listaRecibos = false;
  aplicarFiltros=false;
  pago;
  status_vista;
  recibos_lista: any;
  datac:any;
  saveTipoDash;
  estatusrecibo;
  fechainicial;
  fechafin;
  recibos_filter:any=[];
  organizaciones_sin_recibos=[];
  org_renovar=[];
  orgSelectedRen:any;
  numrecibo=0;
  conteonr=0;
  options: string[] = [];
  creacionExtra= false;
  conducto_de_pago_list=[]
  bandera: string = '';
  urlToPaginate: string = '';
  mensual: any = 0;
  anual: any = 0;
  pagadosop:any=0;
  pagadoscon:any=0;
  pagadoscr:any=0;
  operativos:any=0;
  consulta:any=0;
  crestringida:any=0;
  filteredOptions: Observable<string[]>;
  constructor(
    private router: Router,
    private restApi: RestApiProvider,
    public settings: SettingsService,
    private _rest_api_payment : RestApiPaymentProvider,
    private toastr: ToastrService,
    private _validators: ValidadoresService,
    public dialog: MatDialog,
  ) {
    this.form = new FormGroup({
      id: new FormControl(null, Validators.required),
      org: new FormControl(null, Validators.required),
      gestor_cobranza: new FormControl(null, Validators.required),
      phone_gestor: new FormControl(null, Validators.required),
      email_gestor: new FormControl(null, Validators.required),
      users_payed_op: new FormControl(0, Validators.required),
      users_payed_con: new FormControl(0, Validators.required),
      price_users_op: new FormControl(0, Validators.required),
      price_users_con: new FormControl(0, Validators.required),
      rfc_gestor: new FormControl(null, Validators.required),
      num_client: new FormControl(null, Validators.required),
      conducto_de_pago: new FormControl(0),
      users_payed_cr: new FormControl(0, Validators.required),
      price_users_cr: new FormControl(0, Validators.required),
      promedio_mensual: new FormControl(0, Validators.required),
      promedio_anual: new FormControl(0, Validators.required),
      is_org_demo: new FormControl(false),

    });
    this.conducto_de_pago_list = [
      {id: 0, name: "No domiciliado"},
      {id: 1, name: "Domiciliado"},
    ]

    this.form2 = new FormGroup({
      monto: new FormControl(null, Validators.required),
      monto_total: new FormControl(null, Validators.required),
      forma_pago: new FormControl(null, Validators.required),
      fecha_inicio: new FormControl(null, Validators.required),
      fecha_fin: new FormControl(null, Validators.required),
      vencimiento: new FormControl(null, Validators.required),
      fecha_renovacion: new FormControl(null, Validators.required),
      total_recibos: new FormControl(null, Validators.required),
      // isextra
      description: new FormControl(null, Validators.required)
    });
    this.form3 = new FormGroup({
      recibo_numero: new FormControl(null, Validators.required),
      monto: new FormControl(null, Validators.required),
      monto_total: new FormControl(null, Validators.required),
      forma_pago: new FormControl(null, Validators.required),
      fecha_inicio: new FormControl(null, Validators.required),
      fecha_fin: new FormControl(null, Validators.required),
      vencimiento: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required)
    });
   }

  ngOnInit() {
    this.organizaciones=[]
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter_search(value))
    );
    this.orgSelected = 'true'
    this.turnOffPagination = false;
    this.turnOffPaginationR = false;
    this.myControl.setValue('');
    this.settings.cargarAjustes();
    this.styleBtnActive = {
      'background-color': '#33FFF9',
      'border': 'none',
      'border-top-right-radius': '4px',
      'border-top-left-radius': '4px',
      'cursor': 'pointer',
      'font-weight': '700',
      'padding': '6px 24px'
    }
    this.styleBtnInactive = {
      'background-color': '#ffffff',
      'border': 'none',
      'border-top-right-radius': '4px',
      'border-top-left-radius': '4px',
      'cursor': 'pointer',
      'font-weight': '700',
      'padding': '6px 24px'
    }
    this.styleBtnAll = {
      'background-color': '#ffffff',
      'border': 'none',
      'border-top-right-radius': '4px',
      'border-top-left-radius': '4px',
      'cursor': 'pointer',
      'font-weight': '700',
      'padding': '6px 24px'
    }
    this.showUserActive = true;
    this.showUserInactive = false;
    this.showUserAll = false;

    this.formsPay = [
      {id: 12, name: "Mensual"},
      {id: 6, name: "Bimestral"},
      {id: 4, name: "Trimestral"},
      {id: 2, name: "Semestral"},
      {id: 1, name: "Anual"},
      {id: 5, name: "Contado"}
    ]
    this.statusRecibo = [
      {id: 1, name: "Pendiente"},
      {id: 2, name: "Pagado"},
      {id: 3, name: "Cancelado"},
      // {id: 4, name: "Renovado"},
      // {id: 5, name: "Eliminar"},
    ]
    this.statusReciboFiltro = [
      {id: 0, name: "Todos"},
      {id: 1, name: "Pendiente"},
      {id: 2, name: "Pagado"},
      {id: 3, name: "Cancelado"},
      // {id: 5, name: "Eliminar"},
    ]
  }

  getOrganizaciones(){
    this.restApi.get_organizaciones_full().then(response => {
      this.orgs = response;
      this.orgs.unshift({
        name:'Seleccione una opción',
        id : 0
      })
    });
  }
  getStadisticRecibos(){
    this.obteniendoInformacion =true;
    this.restApi.getStadisticsReceipts().then(response => {
      this.urlToPaginate='recibos-stadistic/'
      this.estadisticaRecibos = response;
      this.recibos_lista= response['todos']
      this.totalR =response['total'];
      this.pageR = response['current_page'];
      this.obteniendoInformacion =false;
      this.listaRecibos=true
    });
  }
  getDetailsRec(){
    this.obteniendoInformacion =true;
    this.restApi.getDetailsReceipts().then(response => {
      this.recibosOrg = response;
      this.obteniendoInformacion =false;
    });
  }
  getDetailsOrgs2(){
    this.obteniendoInformacion =true;
    this.restApi.getControlOrgs(true,1).then(response => {
      this.organizaciones = response['data'] as Array<[]>;
      this.obteniendoInformacion =false;
      this.anual = response['sumas']['anual']['promedio_anual__sum']
      this.mensual = response['sumas']['mensual']['promedio_mensual__sum']
      this.bandera = response['sumas']['existen_sin_promedio']
      this.pagadosop = response['sumas']['users_payed_op']['as_float__sum']
      this.pagadoscon = response['sumas']['users_payed_con']['as_float__sum']
      this.pagadoscr = response['sumas']['users_payed_cr']['as_float__sum']
      this.operativos = response['sumas']['operativos']
      this.consulta = response['sumas']['consulta']
      this.crestringida = response['sumas']['crestringida']
    });
  }
  get org_NoValido() {
    return this.form.get('org').invalid && this.form.get('org').touched;
  }
  get formaPago_NoValido() {
    return this.form.get('forma_pago').invalid && this.form.get('forma_pago').touched;
  }
  get formaPago2_NoValido() {
    return this.form2.get('forma_pago').invalid && this.form2.get('forma_pago').touched;
  }
  get formaPago3_NoValido() {
    return this.form3.get('forma_pago').invalid && this.form3.get('forma_pago').touched;
  }
  applyFilterDash(){
    this.obteniendoInformacion=true;
    if(this.fechainicial ==undefined){      
      this.toastr.error('Agregue Fecha de Filtro (Desde)');
      this.obteniendoInformacion=false;
      return
    }
    if(this.fechafin ==undefined){      
      this.toastr.error('Agregue Fecha de Filtro (Hasta)');
      this.obteniendoInformacion=false;
      return;
    }
    this.datac = {
      'organizacion':this.organizacionF,
      'fechainicial':this.fechainicial,
      'fechafin':this.fechafin,
      'status':this.estatusrecibo
    }
    this.restApi.getRecibosEstadistica(this.datac).then(response=>{
        this.recibos_filter = response['data'];
        this.obteniendoInformacion=false;
        this.totalR =response['total'];
        this.pageR = response['current_page'];
        this.urlToPaginate='filters-recibosDash/'
        this.aplicarFiltros=true
      }, error => console.log('consulta filtrada error'))
      .catch( error =>console.log('consulta filtrada no econtrado')
    ); 
  }
  getDetailsOrgs(org){
    this.obteniendoInformacion=true;
    this.restApi.getControlOrgs(org,1).then(response=>{
      this.organizaciones = response['data'] as Array<[]>;
      this.obteniendoInformacion=false;
    }, error => console.log('orgs no econtrado'))
    .catch( error =>console.log('orgs no econtrado'));     
  }
  verOrgSinR(valor){
    if(valor){
      this.verOrgsSinRecibosOrg=true
    }else{
      this.verOrgsSinRecibos=true;
      this.verDashboardRecs=false;
    }
    this.organizaciones_sin_recibos=this.estadisticaRecibos['organizaciones']
  }
  verDashbR(val){
    this.verDashboardRecs=val
    if(this.verOrgsSinRecibos){
      this.verOrgsSinRecibos=false
    }
    if(val){
      this.cambiop('cobranza',1)
    }else{
      this.listaRecibos=false;
      this.recibos_lista=[]
      console.log('filtrado consulta consulta_recibos_filtrado')            
    }
  }
  showOrgs(value){
    if(this.verOrgsSinRecibosOrg){
      this.verOrgsSinRecibosOrg=false
      this.verOrgsSinRecibos=false;
      this.verDashboardRecs=true;
    }
    if(value == 1){
      this.button_active = value;
      this.styleBtnActive = {
        'background-color': '#33FFF9',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnInactive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }

      this.styleBtnAll = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.showUserActive = true;
      this.showUserInactive = false;
      this.showUserAll = false;
      // Agregar llamda de solo activos
      this.orgSelected = 'true'
      this.restApi.getControlOrgs(true,1).then(response=>{
        this.organizaciones = response['data'] as Array<[]>;
      }, error => console.log('orgs no econtrado'))
      .catch( error =>console.log('orgs no econtrado'));  
    }else if(value==2){
      this.button_active = value;
      this.styleBtnInactive = {
        'background-color': '#33FFF9',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnActive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnAll = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.showUserActive = false;
      this.showUserInactive = true;
      this.showUserAll = false;
      this.myControl.setValue('');
      this.settings.cargarAjustes();
      // llamar inactivos
      this.orgSelected = 'false'
      this.restApi.getControlOrgs(false,1).then(response=>{
        this.organizaciones = response['data'] as Array<[]>;
      }, error => console.log('orgs no econtrado'))
      .catch( error =>console.log('orgs no econtrado'));
    }else if(value==0){
      this.button_active = value;
      this.styleBtnInactive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnActive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnAll = {
        'background-color': '#33FFF9',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.showUserActive = false;
      this.showUserInactive = false;
      this.showUserAll = true;
      this.myControl.setValue('');
      this.settings.cargarAjustes();
      // llamar inactivos  
      this.orgSelected = 'todos'    
      this.restApi.getControlOrgs('todos',1).then(response=>{
        this.organizaciones = response['data'] as Array<[]>;
      }, error => console.log('orgs no econtrado'))
      .catch( error =>console.log('orgs no econtrado'));
    }
  }
  generarReporte(){
    // this.restApi.generateReport(this.orgSelected).then((response:any) =>{      
    this.restApi.generateReportOrgData(this.orgSelected, this.turnOffPagination ? this.myControl.value :'').then((response:any) =>{      
      let filename = 'Reporte_Organizaciones_Usuarios.xlsx';
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (filename)
          downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }
  generarReporteDash(){
    let datafil={}
    // this.restApi.generateReport(this.orgSelected).then((response:any) =>{  
    if(this.aplicarFiltros){
      datafil = {
        'organizacion':this.organizacionF,
        'fechainicial':this.fechainicial,
        'fechafin':this.fechafin,
        'status':this.estatusrecibo,
        'consulta':true
      }
    }  else{
      datafil={}
    }
    this.restApi.generateReportDashReceipts(datafil).then((response:any) =>{      
      let filename = 'Reporte_Recibos_CAS.xlsx';
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (filename)
          downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

  addUserPlan(){
    this.router.navigate(['/terminal-digital']);
  }

  loadPage(event){
    if (this.button_active == 2){
      this.restApi.getControlOrgs(this.orgSelected,event).then(response=>{
        this.organizaciones = response['data'] as Array<[]>;
      });
    } else {
      this.restApi.getControlOrgs(this.orgSelected,event).then(response=>{
        this.organizaciones = response['data'] as Array<[]>;
      });
    }
  }  
  get fecha_inicioNoValido() {
    let invalidob = false
    // tslint:disable-next-line: max-line-length
    // console.log('this.form.get(',(this.form.get('userinfo') as FormGroup).get('birthdate'))
    try{
      let cadena =(this.form2.get('fecha_inicio').value)
      if((cadena.substr(0,2) =='00' || cadena.substr(0,1) ==0) || cadena.length >10){
        let invalidob = true
        if(invalidob){
          return true
        }else{
          return (this.form2.get('fecha_inicio').invalid && (this.form2.get('fecha_inicio').touched));
        }
      }
    }catch(y){}
    // console.log((this.form.get('userinfo') as FormGroup).get('birthdate').invalid,(this.form.get('userinfo') as FormGroup).get('birthdate').touched,invalidob)
  } 
  get fecha_finNoValido() {
    let invalidob = false
    // tslint:disable-next-line: max-line-length
    // console.log('this.form.get(',(this.form.get('userinfo') as FormGroup).get('birthdate'))
    try{
      let cadena =(this.form2.get('fecha_fin').value)
      if((cadena.substr(0,2) =='00' || cadena.substr(0,1) ==0) || cadena.length >10){
        let invalidob = true
        if(invalidob){
          return true
        }else{
          return (this.form2.get('fecha_fin').invalid && (this.form2.get('fecha_fin').touched));
        }
      }
    }catch(y){}
    // console.log((this.form.get('userinfo') as FormGroup).get('birthdate').invalid,(this.form.get('userinfo') as FormGroup).get('birthdate').touched,invalidob)
  }
  showInformation(org, value){
    if(org && value){
      this.orgSelectedRen=org
      this.receipts_org=[]
      this.showInfo = value;
      this.receipts_org = org['recibos_org']
      this.form.get('id').setValue(org['id']);
      this.form.get('org').setValue(org['urlname']);
      this.form.get('gestor_cobranza').setValue(org['gestor_cobranza']);
      this.form.get('email_gestor').setValue(org['email_gestor']);
      this.form.get('phone_gestor').setValue(org['phone_gestor']);
      this.form.get('users_payed_con').setValue(org['users_payed_con']);
      this.form.get('users_payed_op').setValue(org['users_payed_op']);
      this.form.get('price_users_op').setValue(org['price_users_op']);
      this.form.get('price_users_con').setValue(org['price_users_con']);
      this.form.get('users_payed_cr').setValue(org['users_payed_cr']);
      this.form.get('price_users_cr').setValue(org['price_users_cr']);
      this.form.get('promedio_mensual').setValue(org['promedio_mensual']);
      this.form.get('promedio_anual').setValue(org['promedio_anual']);
      this.form.get('is_org_demo').setValue(org['is_org_demo']);
      this.form.get('rfc_gestor').setValue(org['rfc_gestor']);
      this.logo = org['logo'];   
      this.form2.reset();   
      this.form3.reset();   
      this.recibos_creados=[]
      this.showReceiptEdit=false
      if(this.receipts_org){
        this.sort('fecha_inicio',this.receipts_org)
      }
    }else{     
      this.showInfo = false
      this.router.navigate(['/controlusers']);  
      this.form2.reset();   
      this.form3.reset(); 
      this.recibos_creados=[]
      this.showReceiptEdit=false
      // window.location.reload();
    }
  }
  // edit org
  updateOrg(orga){
    this.restApi.actualizar_organizacion(this.orgSelectedRen['url'], this.form.value).then(response => {      try{
        if(response){
          console.log('reps',response)     
        }  
      } catch(e){
        console.log('ddddd',e)
      }   
    }, error => {
      this.form.enable();
      try {
        this.toastr.error(error.error.detail);
      } catch (err) {}
      console.log('err', error);
    })
    .catch(errr => {
      this.form.enable();
      try {
        this.toastr.error(errr.error.detail);
      } catch (err) {}
      console.log('errr', errr);
    });
  }
  // edit org save--
  createReceipts(value){
    this.showReceipts = value;
  }
  createExtra(orga){
    this.creacionExtra =true
    const dialogRef = this.dialog.open(EditarReciboDialogComponent, {
      disableClose: true,
      data: {'i':0,'recibo':{},'formsPay':this.formsPay,'statusRecibo':this.statusRecibo,
      'receipts_org':this.receipts_org,'recibos_lista':this.recibos_lista,'saveTipoDash':this.saveTipoDash,'onlyStatus':false,'creacionExtra':this.creacionExtra,'orga':this.orgSelectedRen}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.receipts_org){
        this.restApi.getReceiptsOrg(this.orgSelectedRen ? this.orgSelectedRen['urlname'] : this.orgSelectedRen['urlname']).then(response => {
          this.receipts_org = response ? response['data'] : this.receipts_org;
          if(this.receipts_org){
            this.sort('fecha_inicio',this.receipts_org)
          }
        });        
      }
      if(this.saveTipoDash){
        if(this.aplicarFiltros){
          this.applyFilterDash()
        }else{
          this.restApi.getStadisticsReceipts().then(response => {
            this.estadisticaRecibos = response;
            this.verDetalleRecibo(this.saveTipoDash)
          });          
        }
      }
      this.cambiop('organizacion',0)
      if (result) {
        console.log('res',result)
        // this.receipts_org=result
        // this.form.get('profile').setValue(result['data'].id);
      }
    });
    // this.creacionExtra=true
  }
  createReceiptsRen(val,orgsx){
    this.showReceiptsRen = val; 
    this.orgSelectedRen = orgsx;  
  }
  calcularMonto(){
    let monto = this.form2.get('monto').value
    let pago = this.form2.get('forma_pago').value
  }
  calcularMontoTotal(){
    let montoe = this.form3.get('monto').value
    let valorfinal = parseFloat(montoe)*.16 + parseFloat(montoe)
    this.form3.get('monto_total').setValue(valorfinal);
  }

  getInicio(fi,ff,fp,index){
    let fechas=[]
    let day =''
    let month =''
    var date_initial = (fi).split('-');
    var dia = parseInt(date_initial[2]);
    var mes = parseInt(date_initial[1]);
    var anio = parseInt(date_initial[0]);
    anio = anio + (index * parseFloat(fp));
    if(mes > 12){
      mes = mes - 12;
      anio = anio + 1;
    }

    if(dia == 31){
      if(mes == 4 || mes == 6 || mes == 9 || mes == 11){
        dia = 30;
      }else if(mes == 2){
        dia = 28;
      }
    }

    if(dia > 28 && mes == 2){
      dia = 28;
    }

    if(dia.toString().length == 1){
      day = '0'+(dia).toString();
    }

    if(mes.toString().length == 1){
      month= '0'+(mes).toString();
    }
    if(day){
      dia = parseInt(day)
    }
    if(month){
      mes = parseInt(month)
    }
    return (anio + '/' + mes + '/' + dia);
  }
  getFin = function(fi,ff,fp,index){
    let day2 =''
    let month2 =''
    var date_initial = (fi).split('-');
    var dia = parseInt(date_initial[2]);
    var mes = parseInt(date_initial[1]);
    var anio = parseInt(date_initial[0]);
    anio = anio + (index+1 * parseFloat(fp));

    if(mes > 12){
      mes = mes - 12;
      anio = anio + 1;
    }
    if(dia == 31){
      if(mes == 4 || mes == 6 || mes == 9 || mes == 11){
        dia = 30;
      }else if(mes == 2){
        dia = 28;
      }
    }
    if(dia > 28 && mes == 2){
      dia = 28;
    }
    if(dia.toString().length == 1){
      day2 = '0' + (dia);
    }
    if(mes.toString().length == 1){
      month2 = '0' + mes;
    }
    if(day2){
      dia = parseInt(day2)
    }
    if(month2){
      mes = parseInt(month2)
    }
    return (anio + '/' + mes + '/' + dia);
  };
  getVencimiento(index, date,fp,fi,ff,dias_duracion){
    var date_initial = (date).split('-');
    var dia = parseInt(date_initial[0]);
    var mes = parseInt(date_initial[1]);
    var anio = parseInt(date_initial[2]);
    let dia3=''
    let mes3=''
    if(mes == 4 || mes == 6 || mes == 9 || mes == 11){
      if(dia > 30){
        dia = dia - 30;
        mes = mes + 1;
        if(mes > 12){
          mes = mes - 12;
          anio = anio + 1;
        }
      }
    }else if(mes == 2){
      if(dia > 28){
        dia = dia - 28;
        mes = mes + 1;
      }
    }else{
      if(dia > 31){
        dia = dia - 31;
        mes = mes + 1;
        if(mes > 12){
          mes = mes - 12;
          anio = anio + 1;
        }
      }
    }
    if(dia.toString().length == 1){
      dia3 = '0' + dia;
    }

    if(mes.toString().length == 1){
      mes3 = '0' + mes;
    }
    if(dia3){
      dia = parseInt(dia3)
    }
    if(mes3){
      mes = parseInt(mes3)
    }
    return (anio + '/' + mes + '/' + dia);
  };
  float2int (value) {
      return value | 0;
  }
  monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) 
  }
  getInicioM(fi,ff,fp,index){
    let dia1=''
    let mes1 =''
    var date_initial = (fi).split('-');
    var dia = parseInt(date_initial[2]);
    var mes = parseInt(date_initial[1]);
    var anio = parseInt(date_initial[0]);
    mes = mes + (index * parseFloat(fp));
    if(mes > 12){
      mes = mes - 12;
      anio = anio + 1;
    }
    if(dia == 31){
      if(mes == 4 || mes == 6 || mes == 9 || mes == 11){
        dia = 30;
      }else if(mes == 2){
        dia = 28;
      }
    }
    if(dia > 28 && mes == 2){
      dia = 28;
    }
    if(dia.toString().length == 1){
      dia1 = '0' + dia;
    }
    if(mes.toString().length == 1){
      mes1 = '0' + mes;
    }
    if(dia1){
      dia = parseInt(dia1)
    }
    if(mes1){
      mes = parseInt(mes1)
    }
    if(this.float2int(mes) > 12){
      mes = mes - 12;
      anio = anio + 1;
    }
    return (anio + '/' + mes + '/' + dia);
  };
  
  getFinM(fi,ff,fp,index){
    let dia2=''
    let mes2=''
    var date_initial = (fi).split('-');
    var dia = parseInt(date_initial[2]);
    var mes = parseInt(date_initial[1]);
    var anio = parseInt(date_initial[0]);

    if(parseFloat(fp) == 5){
      mes = mes + ((index + 1) * 12);
    }else{
      mes = mes + ((index + 1) * parseFloat(fp));
    }

    if(mes > 12){
      mes = mes - 12;
      anio = anio + 1;
    }
    if(dia == 31){
      if(mes == 4 || mes == 6 || mes == 9 || mes == 11){
        dia = 30;
      }else if(mes == 2){
        dia = 28;
      }
    }
    if(dia > 28 && mes == 2){
      dia = 28;
    }
    if(dia.toString().length == 1){
      dia2 = '0' + (dia).toString();
    }
    if(mes.toString().length == 1){
      mes2 = '0' + (mes).toString();
    }
    if(this.float2int(mes) > 12){
      mes = mes - 12;
      anio = anio + 1;
    }
    if((fp ==12 || fp=='12') || (fp==5 || fp=='5')){
      return ff
    }else{
      return (anio + '/' + mes + '/' + dia);
    }
  };
  // recibos extra

  generateReceiptsExtra(){
    console.log('open modal', this.orgSelectedRen)
  }
  // recibos extra fin
  generateReceipts(){
    let numerorecs=0
    let monto = this.form2.get('monto').value
    let pago = this.form2.get('forma_pago').value
    let fi=this.form2.get('fecha_inicio').value
    let ff=this.form2.get('fecha_fin').value
    var date1 = new Date(fi);
    var date2 = new Date(ff);
    date1.setDate(date1.getDate() + 1);
    date2.setDate(date2.getDate() + 1);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let dias_duracion = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
    this.recibos_creados = []
    let valorf = parseFloat(monto)*.16 + parseFloat(monto)
    this.form2.get('monto_total').setValue(valorf);
    if(pago ==1){
      // Anual
      if(dias_duracion <=366){//se hace un recibo
        let recibo = {
          'pago':'Anual',
          'fecha_inicio': date1,
          'fecha_fin': date2,
          'vencimiento': date1,
          'monto': monto,
          'monto_total': valorf,
          'status': 1,
          'forma_pago': 2,
          'recibo_numero':1,
          'organization': this.form.get('id').value
        }
        this.recibos_creados.push(recibo)
      }else{
        let fecha1 = new Date(fi);
        let fecha2 = new Date(ff);
        let totala = (dias_duracion/365)
        for(var i = 0; i < this.float2int(totala); i++){
          this.recibos_creados.push({
            'recibo_numero':i+1,
            'fecha_inicio':this.getInicio(fi,ff,pago,i),
            'fecha_fin':this.getFin(fi,ff,pago,i),
            'monto': monto,
            'monto_total': valorf,
            'pago': 'Anual',
            'status': 1,
            'forma_pago': 2,
            'organization': this.form.get('id').value,
            'vencimiento': this.getInicio(fi,ff,pago,i)
          })
        }
        if(this.float2int(dias_duracion%365) >0){
          if(date2 < new Date(this.recibos_creados[this.float2int(totala)-1]['fecha_fin'])){
            this.recibos_creados.push({
              'recibo_numero':i+1,
              'fecha_inicio':this.recibos_creados[this.float2int(totala)-1]['fecha_fin'],
              'fecha_fin': date2,
              'monto': monto,
              'monto_total': valorf,
              'pago': 'Anual',
              'status': 1,
              'forma_pago': 2,
              'organization': this.form.get('id').value,
              'vencimiento': this.recibos_creados[this.float2int(totala)-1]['fecha_fin']
            })            
          }
        }
      }
    }
    if(pago == 2){
      // Semestral
      let fecha1 = new Date(fi);
      let fecha2 = new Date(ff);
      let months = this.monthDiff(fecha1, fecha2)
      let totals = (months/6)
      for(var i = 0; i < this.float2int(totals); i++){
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.getInicioM(fi,ff,6,i),
          'fecha_fin':this.getFinM(fi,ff,6,i),
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Semestral',
          'status': 1,
          'forma_pago': 3,
          'organization': this.form.get('id').value,
          'vencimiento':this.getInicioM(fi,ff,6,i)
        })
      }
      if(this.float2int(months%6) >0){
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.recibos_creados[this.float2int(totals)-1]['fecha_fin'],
          'fecha_fin': date2,
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Semestral',
          'status': 1,
          'forma_pago': 3,
          'organization': this.form.get('id').value,
          'vencimiento': this.recibos_creados[this.float2int(totals)-1]['fecha_fin']
        })
      }
    }
    if(pago == 4){
      // Trimestral
      let fecha1 = new Date(fi);
      let fecha2 = new Date(ff);
      let totala = (dias_duracion/365)
      let months = this.monthDiff(fecha1, fecha2)
      let totals = (months/3)
      for(var i = 0; i < this.float2int(totals); i++){
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.getInicioM(fi,ff,3,i),
          'fecha_fin':this.getFinM(fi,ff,3,i),
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Trimestral',
          'status': 1,
          'forma_pago': 4,
          'organization': this.form.get('id').value,
          'vencimiento':this.getInicioM(fi,ff,pago,i)
        })
      }
      if(this.float2int(months%6) >0){
        console.log('mas de un multiplos de 3meses restante')
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.recibos_creados[this.float2int(totala)-1]['fecha_fin'],
          'fecha_fin':date2,
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Trimestral',
          'status': 1,
          'forma_pago': 4,
          'organization': this.form.get('id').value,
          'vencimiento': this.recibos_creados[this.float2int(totala)-1]['fecha_fin']
        })
      }
    }
    if(pago == 5){
      // Contado
      let recibo = {
          'pago':'Contado',
          'fecha_inicio': date1,
          'fecha_fin': date2,
          'vencimiento': date1,
          'monto': monto,
          'monto_total': valorf,
          'status': 1,
          'forma_pago': 1,
          'recibo_numero':1,
          'organization': this.form.get('id').value
        }
        this.recibos_creados.push(recibo)
    }
    if(pago == 6){
      // Bimestral
      let fecha1 = new Date(fi);
      let fecha2 = new Date(ff);
      let months = this.monthDiff(fecha1, fecha2)
      let totals = (months/2)
      for(var i = 0; i < this.float2int(totals); i++){
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.getInicioM(fi,ff,2,i),
          'fecha_fin':this.getFinM(fi,ff,2,i),
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Bimestral',
          'status': 1,
          'forma_pago': 5,
          'organization': this.form.get('id').value,
          'vencimiento':this.getInicioM(fi,ff,2,i)
        })
      }
      if(this.float2int(months%2) >0){
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.recibos_creados[this.float2int(totals)-1]['fecha_fin'],
          'fecha_fin':ff,
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Bimestral',
          'status': 1,
          'forma_pago': 5,
          'organization': this.form.get('id').value,
          'vencimiento': this.recibos_creados[this.float2int(totals)-1]['fecha_fin']
        })
      }

    }
    if(pago == 12){
      // Mensual
      let fecha1 = new Date(fi);
      let fecha2 = new Date(ff);
      let totala = (dias_duracion/365)
      let months = this.monthDiff(fecha1, fecha2)
      let totals = (months/1)
      for(var i = 0; i < this.float2int(totals); i++){
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.getInicioM(fi,ff,1,i),
          'fecha_fin':this.float2int(totals) == i && (this.float2int(months%1) ==0) ? ff : this.getFinM(fi,ff,1,i),
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Mensual',
          'status': 1,
          'forma_pago': 6,
          'organization': this.form.get('id').value,
          'vencimiento':this.getInicioM(fi,ff,1,i)
        })
      }
      if(this.float2int(months%1) >0){
        this.recibos_creados.push({
          'recibo_numero':i+1,
          'fecha_inicio':this.recibos_creados[this.float2int(totals)-1]['fecha_fin'],
          'fecha_fin':date2,
          'monto': monto,
          'monto_total': valorf,
          'pago': 'Mensual',
          'status': 1,
          'forma_pago': 6,
          'organization': this.form.get('id').value,
          'vencimiento': this.recibos_creados[this.float2int(totals)-1]['fecha_fin']
        })
      }
    }
    
    // -------
    // ***********
    if(dias_duracion < 365){
      // calcular_cortos();
    }
    // else if(dias_duracion == 365 || dias_duracion == 366){
    //   calcularRecibos(1);
    // }else if(dias_duracion > 366){
    //   calcularRecibosMultianuales(1);
    // }
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
 
  createReceiptsOrg(org){
    if(this.orgSelectedRen){
      this.numrecibo = this.orgSelectedRen['recibos_org'].length
    }
    // return
    let conteo=0
    let total=this.recibos_creados.length
    this.receipts_org=[]
    for(var i = 0; i < this.recibos_creados.length; i++){
      this.form2.disable();
      if(org){
        this.recibos_creados[i].organization = org['id']
      }else{
        this.recibos_creados[i].organization = this.orgSelectedRen['id']
      }
      if(this.numrecibo >0){
        this.conteonr= (this.numrecibo+(i+1))
      }
      this.recibos_creados[i].fecha_fin = this.formatDate(this.recibos_creados[i].fecha_fin)
      this.recibos_creados[i].fecha_inicio = this.formatDate(this.recibos_creados[i].fecha_inicio)
      this.recibos_creados[i].vencimiento = this.formatDate(this.recibos_creados[i].vencimiento)
      this.recibos_creados[i].recibo_numero = this.numrecibo >0  ? this.conteonr : this.recibos_creados[i].recibo_numero

      // console.log('recibo #',this.numrecibo,this.recibos_creados[i].recibo_numero)
      this.restApi.crear_recibos_organizacion(this.recibos_creados[i]).then(response => {
        conteo+=1
        if(total ==conteo){
          this.form2.reset();
          if(this.aplicarFiltros){
            this.applyFilterDash()
          }else{
            this.restApi.getStadisticsReceipts().then(response => {
              this.estadisticaRecibos = response;
            });            
          }
          this.restApi.getReceiptsOrg(this.form.value.org).then(response => {
            this.receipts_org = response['data'];
          });
          this.form2.enable();
          this.router.navigate(['/controlusers']);
          this.toastr.success('Recibos creados Exitosamente'); 
          this.showReceipts =false;
          if(this.showReceiptsRen){
            this.cambiop('recibos',2)
            this.showReceiptsRen=false;
          }
          this.showInfo =true
        }      
      }, error => {
        this.form.enable();
        try {
          this.toastr.error(error.error.detail);
        } catch (err) {}
        console.log('err', error);
      })
      .catch(errr => {
        this.form.enable();
        try {
          this.toastr.error(errr.error.detail);
        } catch (err) {}
        console.log('errr', errr);
      });
    }
  }  
  cancelarRecibos(val:any){
    this.creacionExtra = val;
  }
  detalleRecibos(i,recibo) {
    if (recibo) {
      this.form3.enable();
      this.rec_edit = recibo
      this.index_edit = i
      this.form3.get('monto').setValue(recibo.monto);
      this.form3.get('monto_total').setValue(recibo.monto_total);
      this.form3.get('fecha_inicio').setValue(recibo.fecha_inicio);
      this.form3.get('fecha_fin').setValue(recibo.fecha_fin);
      this.form3.get('vencimiento').setValue(recibo.vencimiento);
      this.form3.get('forma_pago').setValue(recibo.forma_pago_display);
      this.form3.get('status').setValue(recibo.status);
      this.form3.get('recibo_numero').setValue(recibo.recibo_numero);
      this.showReceiptEdit=true
    } else {
      this.showReceiptEdit=false
    }
  }
  // ------eliminar recibos
  delReceipt(i,recibo,onlyStatus,listar){
    if(recibo){
      Swal.fire({
        title: 'Recibo a Eliminar #'+ recibo['recibo_numero'],
        text: 'Se eliminará definitivamente el recibo , continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Regresar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.restApi.delete_receipt_org(recibo.id).then(response => {
            this.cambiop('organization',0)
            if(listar){
              listar.splice(listar.indexOf(recibo), 1);
            }
          });  
          Swal.fire('Eliminado', '', 'success')
        } else{
          Swal.fire('Los cambios no se han aplicado', '', 'info')
        }
      })      
    }
  }
  sort(colName, registros) {
    registros.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
  }
  // buscador
  SearchkeyUp(value){
    if(this.myControl.value.length >2 ? this.myControl && this.myControl.value  : 0){
      this.restApi.get_organizacion_by_name_rason(this.myControl.value).then(response=>{
        this.organizaciones = response as Array<[]>;
        this.turnOffPagination = true;
      }, error => console.log('cadena no econtrada'))
      .catch( error =>console.log('cadena no econtrada'));    
    }else{}
  }
  buscarOrg(value){
    
  }
  onSelected(value:any) {
    if(this.myControl.value.length >2 ? this.myControl && this.myControl.value  : 0){
      this.restApi.get_organizacion_by_name_rason(value).then(response=>{
        this.organizaciones = response as Array<[]>;
      });      
    }
  }
  private _filter_search(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  // buscador end
  editReceipt(i,recibo, onlyStatus) {
    const dialogRef = this.dialog.open(EditarReciboDialogComponent, {
      disableClose: true,
      data: {'i':i,'recibo':recibo,'formsPay':this.formsPay,'statusRecibo':this.statusRecibo,
      'receipts_org':this.receipts_org,'recibos_lista':this.recibos_lista,'saveTipoDash':this.saveTipoDash,'onlyStatus':onlyStatus,'creacionExtra':false,'orga':this.orgSelectedRen}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.receipts_org){
        this.restApi.getReceiptsOrg(recibo['informacion_org'] ? recibo['informacion_org'].urlname : recibo['org_name']).then(response => {
          this.receipts_org = response ? response['data'] : this.receipts_org;
          if(this.receipts_org){
            this.sort('fecha_inicio',this.receipts_org)
          }
        });        
      }
      if(this.saveTipoDash){
        if(this.aplicarFiltros){
          this.applyFilterDash()
        }else{
          this.restApi.getStadisticsReceipts().then(response => {
            this.estadisticaRecibos = response;
            this.verDetalleRecibo(this.saveTipoDash)
          });          
        }
      }
      this.cambiop('organizacion',0)
      if (result) {
        console.log('res',result)
        // this.receipts_org=result
        // this.form.get('profile').setValue(result['data'].id);
      }
    });
  }
  // --------------------------
  loadPageR(event:any){
    this.turnOffPagination = false;
    this.pageR = event;
    this.restApi.getPaginationListReceipts(this.urlToPaginate ? this.urlToPaginate : 'recibos-stadistic/',event).then(response=>{
      this.recibos_lista = response['todos'] || response['data_av'] || response['data_v'] || response['data_p'] || [];
      this.totalR =response['total'] || response['total_av'] || response['total_v'] || response['total_p'] || 0;
      this.pageR = response['current_page'];
    }); 
  }
  loadPageF(event:any){
    this.turnOffPagination = false;
    this.datac['page']=event;
    this.restApi.getPaginationFilter(this.datac).then(response=>{this.recibos_filter = response['data'];
      this.obteniendoInformacion=false;
      this.totalR =response['total'];
      this.pageR = response['current_page'];
      this.urlToPaginate='filters-recibosDash/'
      this.aplicarFiltros=true
    }); 
  }
  verDetalleRecibo(tipo:any){
    if(!this.estadisticaRecibos){      
      Swal.fire('Obtenga la información antes de ver cada rango', '', 'info')
      return;
    }
    this.saveTipoDash=tipo;
    if(tipo =='avencer'){
      this.listaRecibos=true
      // this.recibos_lista = this.estadisticaRecibos ? this.estadisticaRecibos['data_av'] : [];
      this.restApi.getReceiptsAvencer().then(response => {
        this.urlToPaginate='recibos-avencer/'
        this.recibos_lista = response['data_av'];
        this.totalR =response['total_av'];
        this.pageR = response['current_page'];
      });  
    }else if(tipo =='vencidos'){
      this.listaRecibos=true
      // this.recibos_lista = this.estadisticaRecibos ? this.estadisticaRecibos['data_v'] : [];
      this.restApi.getReceiptsVencidos().then(response => {
        this.urlToPaginate='recibos-vencidos/'
        this.recibos_lista = response['data_v'];
        this.totalR =response['total_v'];
        this.pageR = response['current_page'];
      }); 
    }else if(tipo =='pagados'){
      this.listaRecibos=true
      // this.recibos_lista = this.estadisticaRecibos ? this.estadisticaRecibos['data_p'] : [];
      this.restApi.getReceiptsPagados().then(response => {
        this.urlToPaginate='recibos-pagados/'
        this.recibos_lista = response['data_p'];
        this.totalR =response['total_p'];
        this.pageR = response['current_page'];
      }); 
    } 
  }
  cambiop(p,o){
    if(p =='cobranza' && this.showReceiptEdit){
      this.showReceiptEdit=false       
    }
    if(p=='cobranza'){
      this.showInformation('', false)
      if(this.aplicarFiltros){
        this.applyFilterDash()
      }else{
        // this.restApi.getStadisticsReceipts().then(response => {
        //   this.estadisticaRecibos = response;
        //   this.recibos_lista= response['todos']
        //   this.showReceiptsRen=false
        // });            
      }
    }
    if(p =='organizacion' && this.showReceiptEdit){
      this.showReceiptEdit=false    
      this.saveTipoDash=''
    }
    if(p=='organizacion'){    
      // this.restApi.getControlOrgs(true,1).then(response => {
      //   this.organizaciones = response;
      //   this.saveTipoDash=''
      // });      
    }
    if(p=='recibos'){    
      // this.restApi.getOrgsToRenovateReceipts(true).then(response => {
      //   console.log('response',response)
      //   this.org_renovar = response['data'];
      //   this.saveTipoDash=''
      // });      
    }
  }
  getRenovateRecibos(){
    this.obteniendoInformacion =true;
    this.restApi.getOrgsToRenovateReceipts(true).then(response => {
      this.org_renovar = response['data'] as Array<[]>;
      this.obteniendoInformacion =false;
      this.saveTipoDash=''
    });      
  }
  saveEditionRecibo(recibo){
    // recibo_patch
    if(parseInt(this.form3.value['forma_pago']) ==5){
      this.form3.value['forma_pago'] = 1
      this.pago = 'Contado'
    }else if(parseInt(this.form3.value['forma_pago']) ==1){
      this.form3.value['forma_pago'] = 2
      this.pago = 'Anual'
    }else if(parseInt(this.form3.value['forma_pago']) ==2){
      this.form3.value['forma_pago'] = 3
      this.pago = 'Semestral'
    }else if(parseInt(this.form3.value['forma_pago']) ==6){
      this.form3.value['forma_pago'] = 5
      this.pago = 'Bimestral'
    }else if(parseInt(this.form3.value['forma_pago']) ==12){
      this.form3.value['forma_pago'] = 6
      this.pago = 'Mensual'
    }else if(parseInt(this.form3.value['forma_pago']) ==4){
      this.form3.value['forma_pago'] = 4
      this.pago = 'Trimestral'
    }
    if(parseInt(this.form3.value['status']) ==1){
      this.status_vista = 'Pendiente'
    }else if(parseInt(this.form3.value['status']) ==2){
      this.status_vista='Pagado'
    }else if(parseInt(this.form3.value['status']) ==3){
      this.status_vista='Cancelado'
    }else if(parseInt(this.form3.value['status']) ==4){
      this.status_vista='Renovado'
    }else if(parseInt(this.form3.value['status']) ==0){
      this.status_vista='Desactivado'
    }else{
      console.log('---n--------',this.status_vista,this.pago)
    }
    // if(this.rec_edit.monto != this.form3.value.monto){
    //   console.log('se editaron  montos',this.rec_edit.monto,this.form3.value.monto)
    //   let tex ='Cambio de Monto \nRecibos subsecuentes se actualizarán a: Monto = $ '+ this.form3.value.monto+', Monto Total = $ '+ this.form3.value.monto_total;      
    //   Swal.fire({
    //     title: 'Aviso',
    //     text: tex,
    //   })
    // }
    this.restApi.recibo_patch(this.rec_edit.url,this.form3.value).then(response => {
      this.showReceiptEdit=false  
      try{
        if(response){
          response['status_vista'] = this.status_vista
          response['pago'] = this.pago
          if(this.receipts_org){
            this.receipts_org[this.index_edit] = response
          }
          if(this.recibos_lista){
            this.recibos_lista[this.index_edit] = response
          }
          this.form3.disable();       
          this.restApi.getStadisticsReceipts().then(response => {
            this.estadisticaRecibos = response;
            this.listaRecibos=false
          });       
        }  
      } catch(e){
        console.log('ddddd',e)
      }   
    }, error => {
      this.form3.enable();
      try {
        this.toastr.error(error.error.detail);
      } catch (err) {}
      console.log('err', error);
    })
    .catch(errr => {
      this.form3.enable();
      try {
        this.toastr.error(errr.error.detail);
      } catch (err) {}
      console.log('errr', errr);
    });
  }
}

// -----------
export interface DialogData {
  index: any;
  i:any;
  recibo:any,
  formsPay:any;
  statusRecibo;
  receipts_org:any;
  recibos_lista:any ;
  saveTipoDash;
  onlyStatus;
  creacionExtra:false;
  orga:any;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'edit-receipt',
  templateUrl: 'receipt.dialog.component.html',
})
export class EditarReciboDialogComponent {
  form3: FormGroup;
  rec_edit;
  index_edit;
  formsPay;
  statusRecibo;
  pago;
  status_vista;
  receipts_org;
  recibos_lista;
  listaRecibos;
  showReceiptEdit;
  estadisticaRecibos;
  onlyStatus;
  creacionExtra;
  organiza;
  uploadFilesList=[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EditarReciboDialogComponent>,
    // tslint:disable-next-line: variable-name
    private _validators: ValidadoresService,
    // tslint:disable-next-line: variable-name
    private rest_api: RestApiProvider,
    // tslint:disable-next-line: variable-name
    private _settings: SettingsService,
    // tslint:disable-next-line: variable-name
    private _toastr: ToastrService,
    // tslint:disable-next-line: variable-name
    private _router: Router
    ) {
    this.receipts_org=data && data.receipts_org ? data.receipts_org : []
    this.recibos_lista=data && data.recibos_lista ? data.recibos_lista : []
    this.creacionExtra=data && data.creacionExtra ? data.creacionExtra : false
    this.organiza=data && data.orga ? data.orga : ''
    console.log('creacion ex',this.creacionExtra)
    this.onlyStatus=data.onlyStatus;
    this.form3 = new FormGroup({
      recibo_numero: new FormControl(null, Validators.required),
      monto: new FormControl(null, Validators.required),
      monto_total: new FormControl(null, Validators.required),
      forma_pago: new FormControl(null, Validators.required),
      fecha_inicio: new FormControl(null, Validators.required),
      fecha_fin: new FormControl(null, Validators.required),
      fecha_pago: new FormControl(null, Validators.required),
      vencimiento: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
      isextra: new FormControl(false, Validators.required),
      description: new FormControl(null, Validators.required)
    });
    this.rec_edit = data.recibo
    this.index_edit = data.i
    this.formsPay = data.formsPay
    this.statusRecibo = data.statusRecibo
    this.form3.get('monto').setValue(data.recibo.monto);
    this.form3.get('isextra').setValue(data.recibo.isextra);
    this.form3.get('description').setValue(data.recibo.description);
    this.form3.get('monto_total').setValue(data.recibo.monto_total);
    this.form3.get('fecha_inicio').setValue(data.recibo.fecha_inicio);
    this.form3.get('fecha_fin').setValue(data.recibo.fecha_fin);
    let fpa = data.recibo.fecha_pago ? data.recibo.fecha_pago : (new Date().setDate(new Date().getDate() - 1))
    console.log('new Da',new Date(),(new Date().setDate(new Date().getDate() - 1)))
    this.form3.get('fecha_pago').setValue(fpa);
    this.form3.get('vencimiento').setValue(data.recibo.vencimiento);
    this.form3.get('forma_pago').setValue(data.recibo.forma_pago_display);
    this.form3.get('status').setValue(data.recibo.status);
    this.form3.get('recibo_numero').setValue(data.recibo.recibo_numero);
    if(this.onlyStatus){
      this.form3.get('recibo_numero').disable();
      this.form3.get('monto').disable();
      this.form3.get('monto_total').disable();
      this.form3.get('fecha_inicio').disable();
      this.form3.get('fecha_fin').disable();
      this.form3.get('vencimiento').disable();
      this.form3.get('forma_pago').disable();
    }
    this.uploadFilesList.map(archivo => {
      this.form3.addControl(archivo,new FormGroup({
        file: new FormControl()
      }))
    })
  }
  cancel() {
    this.dialogRef.close();
  }
  calcularMonto(){
    let monto = this.form3.get('monto').value
    let pago = this.form3.get('forma_pago').value
  }
  calcularMontoTotal(){
    let montoe = this.form3.get('monto').value
    let valorfinal = parseFloat(montoe)*.16 + parseFloat(montoe)
    this.form3.get('monto_total').setValue(valorfinal);
  }
  // get formaPago3_NoValido() {
  //   return this.form3.get('forma_pago').invalid && this.form3.get('forma_pago').touched;
  // }
  // get org_NoValido() {
  //   return this.form3.get('org').invalid && this.form3.get('org').touched;
  // }
  get fecha_finNoValido() {
    let invalidob = false
    // tslint:disable-next-line: max-line-length
    // console.log('this.form.get(',(this.form.get('userinfo') as FormGroup).get('birthdate'))
    try{
      let cadena =(this.form3.get('fecha_fin').value)
      if((cadena.substr(0,2) =='00' || cadena.substr(0,1) ==0) || cadena.length >10){
        let invalidob = true
        if(invalidob){
          return true
        }else{
          return (this.form3.get('fecha_fin').invalid && (this.form3.get('fecha_fin').touched));
        }
      }
    }catch(y){}
    // console.log((this.form.get('userinfo') as FormGroup).get('birthdate').invalid,(this.form.get('userinfo') as FormGroup).get('birthdate').touched,invalidob)
  }
  get fecha_inicioNoValido() {
    let invalidob = false
    // tslint:disable-next-line: max-line-length
    // console.log('this.form.get(',(this.form.get('userinfo') as FormGroup).get('birthdate'))
    try{
      let cadena =(this.form3.get('fecha_inicio').value)
      if((cadena.substr(0,2) =='00' || cadena.substr(0,1) ==0) || cadena.length >10){
        let invalidob = true
        if(invalidob){
          return true
        }else{
          return (this.form3.get('fecha_inicio').invalid && (this.form3.get('fecha_inicio').touched));
        }
      }
    }catch(y){}
    // console.log((this.form.get('userinfo') as FormGroup).get('birthdate').invalid,(this.form.get('userinfo') as FormGroup).get('birthdate').touched,invalidob)
  } 
  formatDateOK(date){
    // let date = date
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    if(month < 10){
      month = '0'+month
    }
    if(day < 10){
      day = '0'+day
    }
    return year+"-"+month+"-"+day
  }
  // files
  deleteFileOrg(file,container) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta operación es irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.rest_api.eliminar_org_file(file['url']).then(response => {
        //   Swal.fire(
        //     'Eliminado!',
        //     'El archivo ha sido eliminado exitosamente',
        //     'success'
        //   );
        //   this.org_files.splice(this.org_files.indexOf(file), 1);
        //   this.archivos.push(file.nombre)
        // });
      }
    })
  }
  onFileChange(event){
    let a = {}
    let reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.uploadFilesList = event.target.files
      if(this.uploadFilesList && this.uploadFilesList.length >0){
        // event.target.files.forEach(key =>{
        //   reader.readAsDataURL(key);
        //   reader.onload = () => {
        //     console.log('------------',key,reader)
        //     // (this.form.get(nombre) as FormGroup).get('file').setValue(file);
        //   };        
        // })
      }        
    }
  }
  uploadFiles(recibo){  
    let totalfiles = this.uploadFilesList ? this.uploadFilesList.length : 0
    let countfiles = 0
    let reader = new FileReader();
    Object.keys(this.uploadFilesList).forEach(key => {
      const formControl = this.uploadFilesList[key];
      let formData:FormData = new FormData();
      formData.append('nombre', (formControl['name']));
      formData.append('arch', (formControl));
      formData.append('owner', recibo['url']);
      this.rest_api.uploadFileReceipt(recibo['id'], formData,recibo['url']).then(response => {
        countfiles = countfiles+1   
        if(totalfiles == countfiles){
          recibo.recibosFile.push(response)
          this._toastr.info('Los archivos han sido cargados');
        }
      });
    });
  }
  deleteFile(file,container,i) {
    console.log('file----',file,i,container[i])
    delete container[i];
    // this.uploadFilesList.splice(i, 1);
  }
  deleteFileRecibo(file,container) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta operación es irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rest_api.eliminar_org_recibosfiles(file['url']).then(response => {
          Swal.fire(
            'Eliminado!',
            'El archivo ha sido eliminado exitosamente',
            'success'
          );
          container.splice(container.indexOf(file), 1);
        });
      }
    })
  }
  // files-------
  save(uno,receipt_to_save,form) {
    if(parseInt(this.form3.value['forma_pago']) ==5){
      this.form3.value['forma_pago'] = 1
      this.pago = 'Contado'
    }else if(parseInt(this.form3.value['forma_pago']) ==1){
      this.form3.value['forma_pago'] = 2
      this.pago = 'Anual'
    }else if(parseInt(this.form3.value['forma_pago']) ==2){
      this.form3.value['forma_pago'] = 3
      this.pago = 'Semestral'
    }else if(parseInt(this.form3.value['forma_pago']) ==6){
      this.form3.value['forma_pago'] = 5
      this.pago = 'Bimestral'
    }else if(parseInt(this.form3.value['forma_pago']) ==12){
      this.form3.value['forma_pago'] = 6
      this.pago = 'Mensual'
    }else if(parseInt(this.form3.value['forma_pago']) ==4){
      this.form3.value['forma_pago'] = 4
      this.pago = 'Trimestral'
    }
    if(parseInt(this.form3.value['status']) ==1){
      this.status_vista = 'Pendiente'
    }else if(parseInt(this.form3.value['status']) ==2){
      this.status_vista='Pagado'
    }else if(parseInt(this.form3.value['status']) ==3){
      this.status_vista='Cancelado'
    }else if(parseInt(this.form3.value['status']) ==4){
      this.status_vista='Renovado'
    }else if(parseInt(this.form3.value['status']) ==0){
      this.status_vista='Desactivado'
    }else{
      console.log('---n--------',this.status_vista,this.pago)
    }
    let updSubscPrimas =false;
    if(this.form3.value.monto && !this.rec_edit['isextra']){
      if(this.rec_edit.monto != this.form3.value.monto){
        updSubscPrimas=true
        let tex ='Cambio de Monto \nRecibos subsecuentes se actualizarán a: Monto = $ '+ this.form3.value.monto+', Monto Total = $ '+ this.form3.value.monto_total;      
        Swal.fire({
          title: 'Aviso',
          text: tex,
        })
      }      
    }
    if(this.form3.value['status'] !=2){
      this.form3.value['fecha_pago']=null
    }else{;
      var datep = new Date(this.form3.value['fecha_pago']);
      datep.setDate(datep.getDate() + 1);
      this.form3.value['fecha_pago'] = this.formatDateOK(datep);
    }

    this.rest_api.recibo_patch(this.rec_edit.url,this.form3.value).then(response => {
      this.showReceiptEdit=false  
      try{
        if(response){
          if(this.uploadFilesList.length>0){
            this.uploadFiles(response)
          }
          response['status_vista'] = this.status_vista ? this.status_vista : response['status_vista']
          response['pago'] = this.pago ? this.pago : response['pago']
          if(this.receipts_org){
            this.receipts_org[this.index_edit] = response
          }
          if(this.recibos_lista){
            this.recibos_lista[this.index_edit] = response
          }
          this.form3.disable();       
          this.rest_api.getStadisticsReceipts().then(response => {
            this.estadisticaRecibos = response;
            this.listaRecibos=false
          });    
          // actualizacion de priams subsecuentes
          if(updSubscPrimas){
            let data: any = {
              id: response ? response['id'] : '', 
              org: response ? response['informacion_org']['id'] : ''
            }
            this.rest_api.update_receipts_subsecuentes(data).then(response => {
              try{
                if(response){ 
                  if(this.receipts_org){//recibos de una org especifica      
                    this.rest_api.getReceiptsOrg(this.rec_edit['informacion_org'] ? this.rec_edit['informacion_org'].urlname : this.rec_edit['org_name']).then(response => {
                      this.receipts_org = response;
                      this.dialogRef.close(response);
                    });

                  } 
                  if(this.recibos_lista){//recibos de una org especifica 
                    this.dialogRef.close();                   
                    // this.cambiop('cobranza',1)
                  } 
                }  
              } catch(e){
                console.log('upd ',e)
                this.dialogRef.close(response);
              }   
            }, error => {
              console.log(error);
            })
            .catch(errr => {
              console.log('errr', errr);
            });            
          }else{           
            this.dialogRef.close();
          }
          // ------------------------------------
        }  
      } catch(e){
        console.log('ddddd',e)
      }   
    }, error => {
      this.form3.enable();
      try {
        this._toastr.error(error.error.detail);
      } catch (err) {}
      console.log('err', error);
    })
    .catch(errr => {
      this.form3.enable();
      try {
        this._toastr.error(errr.error.detail);
      } catch (err) {}
      console.log('errr', errr);
    });
  }
  saveextra(uno,receipt_to_save,form) {
    this.form3.value['fecha_pago'] = null   
    this.form3.value['forma_pago'] = 1   
    this.form3.value['organization']=this.organiza ? this.organiza['id'] : ''
    this.form3.value['isextra']=true
    this.rest_api.crear_recibos_organizacion(this.form3.value).then(response => {
      this.showReceiptEdit=false  
      try{
        if(response){
          this.dialogRef.close();
          response['status_vista'] = this.status_vista ? this.status_vista : response['status_vista']
          response['pago'] = this.pago ? this.pago : response['pago']
          if(this.receipts_org){
            this.receipts_org[this.index_edit] = response
          }
          if(this.recibos_lista){
            this.recibos_lista[this.index_edit] = response
          }
          this.form3.disable();       
          this.rest_api.getStadisticsReceipts().then(response => {
            this.estadisticaRecibos = response;
            this.listaRecibos=false
          });    
          // ------------------------------------
        }  
      } catch(e){
        console.log('ddddd',e)
      }   
    }, error => {
      this.form3.enable();
      try {
        this._toastr.error(error.error.detail);
      } catch (err) {}
      console.log('err', error);
    })
    .catch(errr => {
      this.form3.enable();
      try {
        this._toastr.error(errr.error.detail);
      } catch (err) {}
      console.log('errr', errr);
    });
  }
}