import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrgDetailComponent } from './organizaciones/org-detail/org-detail.component';
import { UsersComponent } from './users/users.component';
import { UsersReportComponent } from './usersreport/usersreport.component';
import { ControlUsersComponent } from './control/controlusers.component';
import { ProfileComponent } from './profile/profile.component';

import { UsersDetailComponent } from './users/users-detail/users-detail.component';
import { PerfilesComponent } from './perfiles/perfiles.component';
import { PerfilesDetailComponent } from './perfiles/perfiles-detail/perfiles-detail.component';
import { ServiciosMasivosComponent } from './services/servicios-masivos/servicios-masivos.component';
import { SMTPComponent } from './services/smtp/smtp.component';
import { UsersAppComponent } from './services/usersApp/usersApp.component';
import { UsersAppDetailComponent } from './services/usersApp/UsersApp-detail/usersApp-detail.component';
import { LoginGuard, RoleUserGuard, RoleAdminGuard, RoleSuperGuard} from '../services/service.index';
import { ClavesAgenteComponent } from './claves-agente/claves-agente.component';
import { McComponent } from './mc/mc.component';
import { McDetailsComponent } from './mc/mc-details/mc-details.component';

import { HomologacionComponent } from './mc/homologacion/homologacion.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ClaveDetailComponent } from './claves-agente/clave-detail/clave-detail.component';
import { PlanesComponent } from './pagos/planes/planes.component';
import { ClientesComponent } from './pagos/clientes/clientes.component';
import { SuscripcionesComponent } from './pagos/suscripciones/suscripciones.component';
import { TerminalComponent } from './pagos/terminal/terminal/terminal.component';
import { SuscripcionesDetailComponent } from './pagos/suscripciones/suscripciones-detail/suscripciones-detail.component';
import { CreateTemplateComponent } from './services/servicios-masivos/create-template/create-template.component';
import { UsuarioRestringidoComponent } from './usuario-restringido/usuario-restringido.component';
import { UsuarioRestringidoDetalleComponent } from './usuario-restringido/usuario-restringido-detalle/usuario-restringido-detalle.component';


const pagesRoutes: Routes = [
    { 
        path: '',
        component: PagesComponent,
        children: [
            {
                path: 'mi-perfil',
                component: ProfileComponent,
                canActivate:[LoginGuard],
                data: {titulo: 'Mi Perfil'}
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate:[LoginGuard],
                data: {titulo: 'Dashboard'}
            },
            {
                path: 'organizaciones',
                component: OrganizacionesComponent,
                canActivate: [LoginGuard, RoleSuperGuard],
                data: {titulo: 'Organizaciones'}
            },
            {
                path: 'organizaciones-detalle/:id',
                component: OrgDetailComponent,
                canActivate: [LoginGuard, RoleAdminGuard],
                data: {titulo: 'Organizaciones'}
            },
            {
                path: 'notificaciones',
                component: NotificacionesComponent,
                canActivate:[RoleSuperGuard],
                data: {titulo: 'Notificaciones'}
            },
            {
                path: 'claves-agente',
                component: ClavesAgenteComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Claves de agente'}
            },
            {
                path: 'clave-detalle/:id/:aseg_id',
                component: ClaveDetailComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Claves'}
            },
            {
                path: 'usuarios-detalle/:id',
                component: UsersDetailComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Usuarios'}
            },
            {
                path: 'usuarios',
                component: UsersComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Usuarios'}
            },
            {
                path: 'usersreport',
                component: UsersReportComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Reporte de Usuarios'}
            },
            {
                path: 'controlusers',
                component: ControlUsersComponent,
                canActivate: [RoleSuperGuard],
                data: {titulo: 'Control de Usuarios'}
            },
            {
                path: 'configuracion',
                component: AccountSettingsComponent,
                canActivate: [LoginGuard, RoleAdminGuard],
                data: {titulo: 'Configuración'}
            },
            {
                path: 'perfiles',
                component: PerfilesComponent,
                canActivate:[LoginGuard, RoleAdminGuard ],
                data: {titulo: 'Perfiles'}
            },{
                path: 'usuario-restringido',
                component: UsuarioRestringidoComponent,
                canActivate:[LoginGuard ],
                data: {titulo: 'Usuario Restringido'}
            },{
                path: 'usuario-restringido-detalle/:id',
                component: UsuarioRestringidoDetalleComponent,
                canActivate:[LoginGuard ],
                data: {titulo: 'Usuario Restringido Detalle'}
            },
            {
                path: 'perfiles-detalle/:id',
                component: PerfilesDetailComponent,
                canActivate:[LoginGuard, RoleAdminGuard],
                data: {titulo: 'Perfiles'}
            },
            {
                path: 'servicios-masivos',
                component: ServiciosMasivosComponent,
                canActivate: [LoginGuard, RoleSuperGuard],
                data: {titulo: 'Servicios'}
            },
            {
                path: 'templates/:id',
                component: CreateTemplateComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Crear cuerpo de correo'}
            },
            {
                path: 'smtp',
                component: SMTPComponent,
                canActivate: [LoginGuard,],
                data: {titulo: 'Servicios'}
            },
            {
                path: 'suscripciones/:org',
                component: SuscripcionesComponent,
                canActivate:[LoginGuard, RoleAdminGuard],
                data: {titulo: 'Suscripciones'}
            },
            {
                path: 'suscripciones-detail/:subs_id',
                component: SuscripcionesDetailComponent,
                canActivate:[LoginGuard, RoleAdminGuard],
                data: {titulo: 'Suscripciones Detalle'}
            },
            {
                path: 'terminal-digital',
                component: TerminalComponent,
                canActivate:[LoginGuard],
                data: {titulo: 'Terminal Virtual'}
            },
            {
                path: 'planes',
                component: PlanesComponent,
                canActivate:[LoginGuard, RoleSuperGuard],
                data: {titulo: 'Planes'}
            },
            {
                path: 'clientes',
                component: ClientesComponent,
                canActivate:[LoginGuard, RoleSuperGuard],
                data: {titulo: 'Clientes'}
            },
            {
                path: 'mc',
                component: McComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Multicotizador'}
            },
            {
                path: 'mc-details/:mcName',
                component: McDetailsComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Multicotizador - Detalle'}
            },
            {
                path: 'homologacion-mc',
                component: HomologacionComponent,
                canActivate: [LoginGuard, RoleSuperGuard],
                data: {titulo: 'Homologación MC'}
            },
            {
                path: 'usuarios-app',
                component: UsersAppComponent,
                canActivate: [LoginGuard],
                data: {titulo: 'Usuarios App'}
            },
            {
                path: 'usuarios-app-detalle/:id',
                component: UsersAppDetailComponent,
                canActivate:[LoginGuard],
                data: {titulo: 'Usuarios App'}
            },
            {
                path: '', redirectTo: '/login', pathMatch: 'full' 
            }
        ]
    },
]

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );