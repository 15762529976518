import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';

import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ChartsModule } from 'ng2-charts';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrgDetailComponent } from './organizaciones/org-detail/org-detail.component';
import { UsersComponent } from './users/users.component';
import { UsersReportComponent } from './usersreport/usersreport.component';
import { ControlUsersComponent, EditarReciboDialogComponent } from './control/controlusers.component';
import { ProfileComponent } from './profile/profile.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { 
    UsersDetailComponent, 
    UserPermissionsDialogComponent, 
    AddProfileDialogComponent ,
    PerfilesRestringidosDialogComponent
} from './users/users-detail/users-detail.component';
import { PerfilesComponent } from './perfiles/perfiles.component';
import { PerfilesDetailComponent } from './perfiles/perfiles-detail/perfiles-detail.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiciosMasivosComponent, SelectToMassiveDialogComponent,  } from './services/servicios-masivos/servicios-masivos.component';
import { SMTPComponent  } from './services/smtp/smtp.component';
import { UsersAppComponent } from './services/usersApp/usersApp.component';
import { UsersAppDetailComponent } from './services/usersApp/UsersApp-detail/usersApp-detail.component';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatTableModule, MatPaginatorModule, MatCardModule, MatDatepickerModule, MatButtonModule, MatNativeDateModule, MatCheckboxModule, MatStepperModule, MatAutocompleteModule } from '@angular/material';
import { AddClaveDialogComponent, AddComisionDialogComponent, ClavesAgenteComponent } from './claves-agente/claves-agente.component';
import { ClaveDetailComponent } from './claves-agente/clave-detail/clave-detail.component';
import { McComponent } from './mc/mc.component';
import { McDetailsComponent, CoveragesDialogComponent } from './mc/mc-details/mc-details.component';
import { MatProgressSpinnerModule, MatGridListModule } from '@angular/material';
import { HomologacionComponent, LoadCatalogsDialogComponent, LoadDataSourceDialogComponent } from './mc/homologacion/homologacion.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddSuscriptionDialogComponent, ClientesComponent, AddCardDialogComponent } from './pagos/clientes/clientes.component';
import { AddPlanDialogComponent, PlanesComponent } from './pagos/planes/planes.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SuscripcionesComponent } from './pagos/suscripciones/suscripciones.component';
import { TerminalComponent } from './pagos/terminal/terminal/terminal.component';
import { SuscripcionesDetailComponent } from './pagos/suscripciones/suscripciones-detail/suscripciones-detail.component';
import { CreateTemplateComponent } from './services/servicios-masivos/create-template/create-template.component';
import { UsuarioRestringidoComponent } from './usuario-restringido/usuario-restringido.component';
import { UsuarioRestringidoDetalleComponent } from './usuario-restringido/usuario-restringido-detalle/usuario-restringido-detalle.component';


@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        AccountSettingsComponent,
        OrganizacionesComponent,
        OrgDetailComponent,
        UsersComponent,
        UsersReportComponent,
        ControlUsersComponent,
        EditarReciboDialogComponent,
        ProfileComponent,
        UsersDetailComponent,
        PerfilesComponent,
        PerfilesDetailComponent,
        UserPermissionsDialogComponent,
        AddClaveDialogComponent, 
        AddComisionDialogComponent,
        AddProfileDialogComponent,
        PerfilesRestringidosDialogComponent,
        ServiciosMasivosComponent,
        SMTPComponent,
        UsersAppComponent,
        UsersAppDetailComponent,
        SelectToMassiveDialogComponent,
        LoadCatalogsDialogComponent,
        LoadDataSourceDialogComponent,
        CoveragesDialogComponent,
        ClavesAgenteComponent,
        ClaveDetailComponent,
        SuscripcionesComponent,
        McComponent,
        McDetailsComponent,
        HomologacionComponent,
        NotificacionesComponent,
        ClientesComponent,
        PlanesComponent,
        TerminalComponent,
        AddSuscriptionDialogComponent,
        SuscripcionesDetailComponent,
        AddCardDialogComponent,
        AddPlanDialogComponent,
        CreateTemplateComponent,
        UsuarioRestringidoComponent,
        UsuarioRestringidoDetalleComponent,
        
        
    ],
    exports: [
        PagesComponent,
        DashboardComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        PAGES_ROUTES,
        FormsModule,
        ReactiveFormsModule,
        ChartsModule,
        BrowserModule,
        ImageCropperModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        NgxSpinnerModule,
        HttpClientModule,
        AngularEditorModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatCardModule,
        NgbModule,
        MatDatepickerModule,
        MatButtonModule,
        MatNativeDateModule,
        MatCheckboxModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatStepperModule,
        MatAutocompleteModule,
    ],
    entryComponents: [
        UserPermissionsDialogComponent, 
        PerfilesRestringidosDialogComponent,
        AddProfileDialogComponent, 
        EditarReciboDialogComponent,
        SelectToMassiveDialogComponent,
        LoadCatalogsDialogComponent,
        LoadDataSourceDialogComponent,
        CoveragesDialogComponent,
        AddClaveDialogComponent, 
        AddComisionDialogComponent,
        AddSuscriptionDialogComponent,
        AddCardDialogComponent,
        AddPlanDialogComponent,
        
    ],
    providers : [
        NgxSpinnerService
    ]
})

export class PagesModule { }